import Http from '../../common/request'

export const submitActivityForm = (_channel, link, view_count, screenshot) => {
    return Http('/api/client/auth/activity/submit', { auth: true, _channel, link, view_count, screenshot })
}

export const getActivityInfo = id => {
    return Http('/api/client/activity/info', { id })
}

export const reportEvent = (uid, eventType, subType, info) => {
    return Http('/api/client/report/event', { uid, eventType, subType, info })
}