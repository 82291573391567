import './xboxBtn.css'
import { useState, useRef, useCallback, useEffect } from "react"
import { getRem, getWindowSizeForLandscape } from '../../common/util'

function CustomBtn({ id, name, size, scale, opacity, position, chooseBtn, moveEnd }) {
    const [x, setX] = useState(position.x)
    const [y, setY] = useState(position.y)
    const dragState = useRef(false)
    const touchOriginX = useRef(0)
    const touchOriginY = useRef(0)
    const touchMaxX = useRef(0)
    const touchMaxY = useRef(0)
    const xRef = useRef(position.x)
    const yRef = useRef(position.y)
    const touchStart = useCallback(e => {
        dragState.current = true
        const { screenX, screenY } = e.touches[0]
        touchOriginX.current = screenX
        touchOriginY.current = screenY
        chooseBtn(id)
    }, [id, chooseBtn])
    const touchMove = useCallback(e => {
        if (dragState.current) {
            const { screenX, screenY } = e.touches[0]
            const deltaX = screenX - touchOriginX.current
            const deltaY = screenY - touchOriginY.current
            touchOriginX.current = screenX
            touchOriginY.current = screenY
            setX(ox => Math.min(Math.max(0, ox + deltaX), touchMaxX.current))
            setY(oy => Math.min(Math.max(0, oy + deltaY), touchMaxY.current))
        }
    }, [])

    useEffect(() => {
        const { width, height } = getWindowSizeForLandscape()
        const rem = getRem()
        touchMaxX.current = width - rem * scale * size
        touchMaxY.current = height - rem * scale * size
        let fix = false
        if (xRef.current > touchMaxX.current) {
            fix = true
            setX(touchMaxX.current)
            xRef.current = touchMaxX.current
        }
        if (yRef.current > touchMaxY.current) {
            fix = true
            setY(touchMaxY.current)
            yRef.current = touchMaxY.current
        }
        fix && moveEnd({ x: xRef.current, y: yRef.current })
    }, [size, scale, moveEnd])

    const touchEnd = () => {
        if (dragState.current) {
            dragState.current = false
            xRef.current = x
            yRef.current = y
            moveEnd({ x, y })
        }
    }

    const mouseDown = e => {
        const { screenX, screenY } = e
        touchStart({ touches: [{ screenX, screenY }] })
    }

    const mouseMove = e => {
        const { screenX, screenY } = e
        touchMove({ touches: [{ screenX, screenY }] })
    }

    return (
        <div className={`xbox-btn btn-${id}`} style={{ width: `${size}rem`, height: `${size}rem`, left: `${x}px`, top: `${y}px`, transform: `scale(${scale})`, opacity: `${opacity}` }}
            onTouchStart={touchStart} onTouchEnd={touchEnd} onTouchMove={touchMove} onMouseDown={mouseDown} onMouseMove={mouseMove} onMouseUp={touchEnd} onMouseLeave={touchEnd}>
            {
                id !== 'LR' && id !== 'DIRECTION' && id !== 'RR' && <p className='xbox-btn-text'>{id}</p>
            }
            <p className='xbox-btn-text'>{name}</p>
        </div>
    )
}

export default CustomBtn