import './body.css'
import { useEffect, useState, useRef } from 'react'
import { callBridgeFunc, registerBridgeFunc } from '../../../common/bridge'
import { draw, getDrawCount } from './service'
import { reportEvent } from '../service'
import { setToLocalStorage, safeParse } from '../../../common/util'
import { ExternalAuthCacheKey, ExternalChannelCacheKey } from '../../../config'
import PubSub from 'pubsub-js'
import { EventTip } from '../../../common/event'

function NewYearBody({ setPrize }) {
    const [count, setCount] = useState(0)
    const [isLogin, toggleLoginState] = useState(false)
    const submitFlag = useRef(false)
    useEffect(() => {
        registerBridgeFunc('SyncUserInfo', data => {
            data = safeParse(data)
            if (data && data.userinfo) {
                const { _channel } = data
                const { token, uid } = data.userinfo
                setToLocalStorage(ExternalAuthCacheKey, token)
                setToLocalStorage(ExternalChannelCacheKey, _channel)
                getDrawCount(1).then(c => {
                    setCount(c)
                    toggleLoginState(true)
                })
                reportEvent(uid, 1, "Activity", "")
            }
        })
        registerBridgeFunc('SyncEventStatus', data => {
            if (data === 'RECHARGE_SUCCESS') {
                getDrawCount(1).then(c => {
                    setCount(c)
                })
            }
        })
        reportEvent("", 1, "Activity", "")
        // callBridgeFunc('SyncUserInfo', data => {
        //     console.log('---主动---')
        //     console.log(data)
        // })
    }, [])
    const goToGetCount = () => {
        if (isLogin) {
            callBridgeFunc('GoToVip')
        } else {
            callBridgeFunc('UserLogin')
        }
    }
    const drawPrize = () => {
        reportEvent("", 2, "", "抽奖")
        if (!isLogin) {
            return callBridgeFunc('UserLogin')
        }
        if (submitFlag.current) {
            return
        }
        submitFlag.current = true
        draw(1).then(data => {
            const { record, count: c } = data
            setCount(c)
            setPrize(record)
            PubSub.publish('DrawEnd')
        }).catch(e => {
            if (e.code === 401) {
                setCount(0)
                toggleLoginState(false)
                callBridgeFunc('UserLogin')
            } else {
                PubSub.publish(EventTip, { msg: e.message })
            }
        }).finally(() => {
            submitFlag.current = false
        })
    }
    return <div className='newyear-body'>
        <div className='newyear-count-wrap'>剩余抽奖次数<span className='newyear-count'>{count}</span>次</div>
        <div className='newyear-get-count' onClick={goToGetCount}></div>
        <div className='newyear-box-line line-1'>
            <div className='newyear-box col-1'></div>
            <div className='newyear-box col-2 active'></div>
            <div className='newyear-box col-3'></div>
        </div>
        <div className='newyear-box-line line-2'>
            <div className='newyear-box col-1'></div>
            <div className='newyear-box col-2'></div>
            <div className='newyear-box col-3'></div>
        </div>
        <div className='newyear-box-line line-3'>
            <div className='newyear-box col-1'></div>
            <div className='newyear-box col-2'></div>
            <div className='newyear-box col-3'></div>
        </div>
        <button className='newyear-main-btn' onClick={drawPrize}></button>
    </div>
}

export default NewYearBody