import './index.css'
import { useState, useEffect } from 'react'
import CustomBtn from './xboxBtn'
import { getRem, getWindowSizeForLandscape, safeParse, fixByGear } from '../../common/util'
import Dialog from '../dialog'
import { savePanelJson } from './service'
import XboxBtns from './xboxBtns'

const InitialProps = {
    scale: 1,
    opacity: 1,
    name: ''
}

const getValueTxt = n => {
    return `${Math.round(n * 100)}%`
}

const SizeMap = {
    LT: 0.5,
    LB: 0.5,
    RT: 0.5,
    RB: 0.5,
    START: 0.333,
    SELECT: 0.333,
    LS: 0.417,
    RS: 0.417,
    LR: 1.111,
    DIRECTION: 0.833,
    RR: 0.833,
    A: 0.417,
    B: 0.417,
    X: 0.417,
    Y: 0.417
}

const PositionMap = {
    LT: {
        x: 0.062,
        y: 0.222
    },
    LB: {
        x: 0.062,
        y: 0.378
    },
    RT: {
        x: 0.863,
        y: 0.222
    },
    RB: {
        x: 0.863,
        y: 0.378
    },
    START: {
        x: 0.509,
        y: 0.033
    },
    SELECT: {
        x: 0.441,
        y: 0.033
    },
    LS: {
        x: 0.01,
        y: 0.519
    },
    RS: {
        x: 0.927,
        y: 0.519
    },
    LR: {
        x: 0.083,
        y: 0.611
    },
    DIRECTION: {
        x: 0.312,
        y: 0.722
    },
    RR: {
        x: 0.562,
        y: 0.722
    },
    A: {
        x: 0.839,
        y: 0.833
    },
    B: {
        x: 0.896,
        y: 0.731
    },
    X: {
        x: 0.781,
        y: 0.731
    },
    Y: {
        x: 0.839,
        y: 0.63
    }
}

const getInitialJson = () => {
    const { width, height } = getWindowSizeForLandscape()
    const ret = []
    for (let i = 0; i < XboxBtns.length; i++) {
        let ci = XboxBtns[i]
        let item = {
            ...ci, size: SizeMap[ci.id], scale: 1, opacity: 1, position: {
                x: PositionMap[ci.id].x * width,
                y: PositionMap[ci.id].y * height
            }
        }
        ret.push(item)
    }
    return ret
}

const restoreOpJson = json => {
    const { width, height } = getWindowSizeForLandscape()
    const rem = getRem()
    const arr = safeParse(json, [])
    const ret = []
    for (let i = 0; i < arr.length; i++) {
        let item = arr[i]
        let ri = { name: item.n, kT: item.kT, ks: item.ks, position: {}, opacity: 1 }
        if (item.ks.length > 1) {
            ri.id = 'DIRECTION'
        } else {
            ri.id = item.ks[0].k
        }
        ri.size = SizeMap[ri.id]
        ri.scale = fixByGear([0.75, 0.875, 1, 1.125, 1.25], item.p.w / 288)
        let x = item.p.x * width
        let y = item.p.y * height
        ri.position.x = Math.min(width - rem * ri.size * ri.scale, x)
        ri.position.y = Math.min(height - rem * ri.size * ri.scale, y)
        ret.push(ri)
    }
    return ret
}

const parseOpJson = arr => {
    const { width, height } = getWindowSizeForLandscape()
    const ret = []
    for (let i = 0; i < arr.length; i++) {
        let item = arr[i]
        let pi = { kT: item.kT, ks: item.ks, n: item.name, p: {}, s: 1, sT: 2 }
        pi.p.h = pi.p.w = Math.round(item.size * item.scale * 288)
        pi.p.x = Number((item.position.x / width).toFixed(3))
        pi.p.y = Number((item.position.y / height).toFixed(3))
        ret.push(pi)
    }
    return JSON.stringify(ret)
}

function CustomPanel({ close, gameId, mode = 'client' }) {
    const [btnList, setBtnList] = useState([])
    const [btnId, setBtnId] = useState('')
    const [currentProps, setCurrentProps] = useState(InitialProps)
    const [showSaveDialog, toggleSaveDialog] = useState(false)
    const [showQuitDialog, toggleQuitDialog] = useState(false)
    const [showResetDialog, toggleResetDialog] = useState(false)
    const [putAway, togglePutAway] = useState(false)

    useEffect(() => {
        if (btnId && btnList.length) {
            const item = btnList.find(b => b.id === btnId)
            if (item) {
                const { scale, opacity, name } = item
                setCurrentProps({ scale, opacity, name })
            }
        } else {
            setCurrentProps(InitialProps)
        }
    }, [btnList, btnId])

    useEffect(() => {
        if (mode === 'server') {
            const listener = message => {
                const data = safeParse(message.data)
                if (data && data.type === 'INIT_PANEL_JSON') {
                    if (data.data) {
                        setBtnList(restoreOpJson(data.data))
                    } else {
                        setBtnList(getInitialJson())
                    }
                }
            }
            window.addEventListener('message', listener)
            window.parent.postMessage(JSON.stringify({ type: 'INIT_PANEL_JSON' }), '*')
            return () => {
                window.removeEventListener('message', listener)
            }
        }
    }, [mode])

    const updateBtn = props => {
        if (!btnId) {
            return
        }
        const list = btnList.slice()
        for (let i = 0; i < list.length; i++) {
            if (list[i].id === btnId) {
                list[i] = { ...list[i], ...props }
                return setBtnList(list)
            }
        }
    }

    const moveEnd = position => {
        updateBtn({ position })
    }

    const inputBtnName = e => {
        const { value } = e.target
        const props = { name: value }
        setCurrentProps(p => ({ ...p, ...props }))
        updateBtn(props)
    }

    const changeScale = value => {
        const props = { scale: currentProps.scale + value }
        updateBtn(props)
    }

    const changeOpacity = value => {
        const props = { opacity: Number((currentProps.opacity + value).toFixed(1)) }
        updateBtn(props)
    }

    const addScale = () => {
        if (currentProps.scale >= 1.25) {
            return
        }
        changeScale(0.125)
    }

    const addOpacity = () => {
        if (currentProps.opacity >= 1) {
            return
        }
        changeOpacity(0.1)
    }

    const subScale = () => {
        if (currentProps.scale <= 0.75) {
            return
        }
        changeScale(-0.125)
    }

    const subOpacity = () => {
        if (currentProps.opacity <= 0.1) {
            return
        }
        changeOpacity(-0.1)
    }

    const closeSetting = () => {
        toggleSaveDialog(false)
    }

    const closeQuit = () => {
        toggleQuitDialog(false)
    }

    const save = () => {
        if (mode === 'server') {
            closeSetting()
            window.parent.postMessage(JSON.stringify({ type: 'SAVE_PANEL_JSON', data: parseOpJson(btnList) }), '*')
            return
        }
        savePanelJson(gameId, btnList)
        close()
    }

    const goToQuit = () => {
        mode === 'client' && toggleQuitDialog(true)
    }

    const goToReset = () => {
        toggleResetDialog(true)
    }

    const cancelReset = () => {
        toggleResetDialog(false)
    }

    const doReset = () => {
        setBtnId('')
        setBtnList(getInitialJson())
        cancelReset()
    }

    const goToSave = () => {
        toggleSaveDialog(true)
    }

    const togglePutAwayState = () => {
        togglePutAway(!putAway)
    }

    return (
        <div className='app-play-screen-mask'>
            <div className='cp-main-container'>
                {
                    btnList.map(btn => (
                        <CustomBtn
                            key={btn.id}
                            id={btn.id}
                            name={btn.name}
                            size={btn.size}
                            scale={btn.scale}
                            opacity={btn.opacity}
                            position={btn.position}
                            chooseBtn={setBtnId}
                            moveEnd={moveEnd}></CustomBtn>
                    ))
                }
            </div>
            <div className={putAway ? 'cp-top-bar-container away' : 'cp-top-bar-container'}>
                <div className='cp-top-bar-btns'>
                    <button className='cp-top-bar-close' onClick={goToQuit}></button>
                    <span className='cp-top-bar-split'></span>
                    <span className='cp-top-bar-tip'>拖动按键<span className='highlight'>调位置</span>，点击按键设置<span className='highlight'>大小名称</span></span>
                    <span className='cp-top-bar-split closer'></span>
                    <button className='cp-top-bar-btn default' onClick={goToReset}>还原默认</button>
                    <button className='cp-top-bar-btn save' onClick={goToSave}>保存</button>
                </div>
                <div className='cp-top-op-wrapper'>
                    <div className='cp-top-op'>
                        <span className='cp-top-op-tip'>按键大小</span>
                        <div className='cp-top-op-number-wrapper'>
                            <button className='cp-top-op-number-btn add' onClick={addScale}></button>
                            <p className='cp-top-op-number-txt'>{getValueTxt(currentProps.scale)}</p>
                            <button className='cp-top-op-number-btn sub' onClick={subScale}></button>
                        </div>
                        <span className='cp-top-op-tip'>透明度</span>
                        <div className='cp-top-op-number-wrapper'>
                            <button className='cp-top-op-number-btn add' onClick={addOpacity}></button>
                            <p className='cp-top-op-number-txt'>{getValueTxt(currentProps.opacity)}</p>
                            <button className='cp-top-op-number-btn sub' onClick={subOpacity}></button>
                        </div>
                        <span className='cp-top-op-tip'>按键名称</span>
                        <input className='cp-top-op-input' value={currentProps.name} onChange={inputBtnName} maxLength={15}></input>
                    </div>
                </div>
                <i className='cp-top-bar-slide' onClick={togglePutAwayState}></i>
            </div>
            {
                showSaveDialog && <Dialog title={'保存当前自定义设置？'} className={'zIndex1'} cancelFunc={closeSetting} okFunc={save}></Dialog>
            }
            {
                showQuitDialog && <Dialog title={'设置未保存，确定离开？'} className={'zIndex1'} cancelFunc={closeQuit} okFunc={close}></Dialog>
            }
            {
                showResetDialog && <Dialog title={'还原为默认设置？'} className={'zIndex1'} cancelFunc={cancelReset} okFunc={doReset}></Dialog>
            }
        </div>
    )
}

export default CustomPanel