const XboxBtns = [
    {
        id: 'LT',
        name: 'LT',
        kT: 1,
        ks: [{ c: 0, k: 'LT' }]
    }, {
        id: 'LB',
        name: 'LB',
        kT: 1,
        ks: [{ c: 256, k: 'LB' }]
    }, {
        id: 'RT',
        name: 'RT',
        kT: 1,
        ks: [{ c: 0, k: 'RT' }]
    }, {
        id: 'RB',
        name: 'RB',
        kT: 1,
        ks: [{ c: 512, k: 'RB' }]
    }, {
        id: 'START',
        name: 'START',
        kT: 1,
        ks: [{ c: 16, k: 'START' }]
    }, {
        id: 'SELECT',
        name: 'SELECT',
        kT: 1,
        ks: [{ c: 32, k: 'SELECT' }]
    }, {
        id: 'LS',
        name: 'LS',
        kT: 1,
        ks: [{ c: 64, k: 'LS' }]
    }, {
        id: 'RS',
        name: 'RS',
        kT: 1,
        ks: [{ c: 128, k: 'RS' }]
    }, {
        id: 'LR',
        name: 'LR',
        kT: 4,
        ks: [{ c: 0, k: 'LR' }]
    }, {
        id: 'DIRECTION',
        name: 'DIRECTION',
        kT: 3,
        ks: [{ c: 1, k: 'UP' }, { c: 2, k: 'DOWN' }, { c: 4, k: 'LEFT' }, { c: 8, k: 'RIGHT' }]
    }, {
        id: 'RR',
        name: 'RR',
        kT: 5,
        ks: [{ c: 0, k: 'RR' }]
    }, {
        id: 'A',
        name: 'A',
        kT: 1,
        ks: [{ c: 4096, k: 'A' }]
    }, {
        id: 'B',
        name: 'B',
        kT: 1,
        ks: [{ c: 8192, k: 'B' }]
    }, {
        id: 'X',
        name: 'X',
        kT: 1,
        ks: [{ c: 16384, k: 'X' }]
    }, {
        id: 'Y',
        name: 'Y',
        kT: 1,
        ks: [{ c: 32768, k: 'Y' }]
    }
]

export default XboxBtns